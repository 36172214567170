import { useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

import { discordSdk } from "../utils/discordSdk";
import useWebsocket from "../hooks/useWebsocket";
import { useDiscordAuthContext } from "../hooks/useDiscordAuthContext";
import { Events } from "@discord-external/activity-iframe-sdk";
import Loading from "./Loading";

const getFileExtension = (compression: UnityCompression): string => {
  switch (compression) {
    case "gzip":
      return ".gz";
    case "brotli":
      return ".br";
    default:
      return "";
  }
};

const getWebsocketUrl = () => {
  const url = new URL(location.href);

  if (url.hostname === "localhost") {
    return `ws://localhost:8788/api/instance`;
  }

  return `wss://${url.hostname}/api/instance`;
};

const getInstanceId = () => {
  const url = new URL(location.href);

  const override = url.searchParams.get("instanceId");

  return override ?? discordSdk.instanceId;
};

const getBranchOverride = () => {
  const url = new URL(location.href);

  const override = url.searchParams.get("branch");

  return override ?? null;
};

interface RoomProps {
  unityBranch: string;
  unityCompression: UnityCompression;
}

const Room = ({ unityBranch, unityCompression }: RoomProps) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [users, setUsers] = useState<PublicUser[]>([]);
  const [websocketOpen, setWebsocketOpen] = useState(false);
  const { channelId, guildId } = discordSdk;
  const { access_token } = useDiscordAuthContext();
  const fileExtension = getFileExtension(unityCompression);
  const branch = getBranchOverride() ?? unityBranch;

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
    sendMessage,
  } = useUnityContext({
    loaderUrl: `/unity/${branch}/unity.loader.js`,
    dataUrl: `/unity/${branch}/unity.data${fileExtension}`,
    frameworkUrl: `/unity/${branch}/unity.framework.js${fileExtension}`,
    codeUrl: `/unity/${branch}/unity.wasm${fileExtension}`,
  });
  const wsUrl = `${getWebsocketUrl()}/${getInstanceId()}`;

  const roomWs = useWebsocket({
    url: wsUrl,
    onOpen: () => {
      setWebsocketOpen(true);
    },
    onClose: () => {
      setWebsocketOpen(false);
    },
  });

  const handlePlayerTransformUpdate = useCallback(
    (transform: UnityTransform) => {
      if (!roomWs || !userId) return;

      const msg: Message.Move = {
        type: "move",
        data: {
          transform,
        },
      };

      roomWs.send(JSON.stringify(msg));
    },
    [roomWs, userId]
  );

  const handlePlayerActionCalled = useCallback(
    (actionType: string) => {
      if (!roomWs || !userId) return;

      const msg: Message.GameAction = {
        type: "game-action",
        data: {
          userId,
          actionType,
          actionData: null,
        },
      };

      roomWs.send(JSON.stringify(msg));
    },
    [roomWs, userId]
  );

  const handleSpeakingStart = useCallback(
    ({ user_id }) => {
      isLoaded &&
        sendMessage(
          user_id,
          "UpdateIsSpeaking",
          JSON.stringify({
            isSpeaking: true,
          })
        );
    },
    [isLoaded, sendMessage]
  );

  const handleSpeakingStop = useCallback(
    ({ user_id }) => {
      isLoaded &&
        sendMessage(
          user_id,
          "UpdateIsSpeaking",
          JSON.stringify({
            isSpeaking: false,
          })
        );
    },
    [isLoaded, sendMessage]
  );

  useEffect(() => {
    addEventListener("PlayerTransformUpdate", handlePlayerTransformUpdate);
    addEventListener("PlayerAction", handlePlayerActionCalled);
    return () => {
      removeEventListener("PlayerTransformUpdate", handlePlayerTransformUpdate);
      removeEventListener("PlayerAction", handlePlayerActionCalled);
    };
  }, [
    addEventListener,
    removeEventListener,
    handlePlayerTransformUpdate,
    handlePlayerActionCalled,
  ]);

  useEffect(() => {
    if (!isLoaded) return;
    discordSdk.subscribe(Events.SPEAKING_STOP, handleSpeakingStop, {
      channel_id: channelId,
    });
    discordSdk.subscribe(Events.SPEAKING_START, handleSpeakingStart, {
      channel_id: channelId,
    });
    return () => {
      discordSdk.unsubscribe(Events.SPEAKING_STOP, handleSpeakingStop);
      discordSdk.unsubscribe(Events.SPEAKING_START, handleSpeakingStart);
    };
  }, [
    isLoaded,
    discordSdk.subscribe,
    discordSdk.unsubscribe,
    handleSpeakingStart,
    handleSpeakingStop,
  ]);

  const handleMovement = useCallback(
    (message: Message.Movement) => {
      isLoaded &&
        sendMessage(
          message.data.id,
          "UpdatePlayerTransform",
          JSON.stringify({ ...message.data.transform })
        );
    },
    [isLoaded, sendMessage]
  );

  const handleIncomingPlayerAction = (message: Message.GameAction) => {
    if (!isLoaded) {
      return;
    }

    // we sent this message and it somehow came back. just ignore it.
    if (message.data.userId === userId && message.data.userId !== null) {
      return;
    }

    switch (message.data.actionType) {
      case "sit":
        sendMessage(message.data.userId, "TriggerSit");
        break;
      case "stand":
        sendMessage(message.data.userId, "TriggerStand");
        break;
      case "walk":
        sendMessage(message.data.userId, "TriggerWalk");
        break;
      case "stop":
        sendMessage(message.data.userId, "TriggerStop");
        break;
      case "relax":
        sendMessage(userId, "TriggerRelax");
        break;
      case "end-relax":
        sendMessage(userId, "TriggerEndRelax");
        break;
    }
  };

  const handleMessage = (message: Message) => {
    switch (message.type) {
      case "authenticated":
        console.log(`authenticated as ${message.data.user.id}`);
        setUserId(message.data.user.id);
        break;
      case "ping":
        const msg: Message.Pong = { type: "pong" };
        roomWs.send(JSON.stringify(msg));
        break;
      case "users":
        setUsers(message.data.users);
        break;
      case "movement":
        handleMovement(message);
        break;
      case "user-disconnected":
        sendMessage("MultiplayerManager", "RemoveUser", message.data.id);
        break;
      case "game-action":
        handleIncomingPlayerAction(message);
        break;
      default:
        console.log("unhandled message", message);
    }
  };

  useEffect(() => {
    if (!websocketOpen || !isLoaded) return;

    const authMsg: Message.Authenticate = {
      type: "authenticate",
      data: { access_token, channelId, guildId },
    };

    roomWs.send(JSON.stringify(authMsg));
  }, [websocketOpen, isLoaded]);

  useEffect(() => {
    if (!websocketOpen) return;

    roomWs.onmessage = (event) => {
      const messages = JSON.parse(event.data);

      messages.forEach(handleMessage);
    };
  }, [websocketOpen, handleMessage]);

  // send user information to Unity
  useEffect(() => {
    if (!isLoaded || !userId) return;
    users.forEach((user) => {
      if (user.id === userId) {
        user.isLocal = true;
      }

      sendMessage("MultiplayerManager", "AddUser", JSON.stringify(user));
    });

    // apparently "isLoaded" doesn't actually mean Unity is loaded. additionally, it takes a bit of time for our users
    // to be added to the scene. let's wait a bit before sending the "unity-loaded" message.
    const timeout = setTimeout(() => {
      const msg: Message.UnityLoaded = { type: "unity-loaded" };
      roomWs.send(JSON.stringify(msg));
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoaded, userId, users]);

  const loadingPercentage = Math.round(loadingProgression * 100);

  return (
    <div className="w-screen h-screen overflow-hidden">
      {isLoaded === false && <Loading percentage={loadingPercentage} />}
      <Unity unityProvider={unityProvider} className="w-full h-full" />
    </div>
  );
};

export default Room;

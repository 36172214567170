import { useCallback, useEffect, useState } from "react";

const useBranch = () => {
  const [branch, setBranch] = useState<string>(null);

  const fetchBranch = useCallback(async () => {
    const response = await (await fetch("/api/branch-build")).json();
    if (response.branch != null) {
      setBranch(response.branch);
    }
  }, []);

  useEffect(() => {
    fetchBranch();
  }, []);

  return branch;
};

export default useBranch;
